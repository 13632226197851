import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { europeanDateFormat } from "../../helpers";

const StyledDate = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.lightgrey};
  user-select: none;
`;
const ReleaseDate = ({ release }) => {
  return (
    <StyledDate className="card__item card__item--release">
      Release Date: {europeanDateFormat(release)}
    </StyledDate>
  );
};

ReleaseDate.propTypes = {
  release: PropTypes.string,
};
export default ReleaseDate;
